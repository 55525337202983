import axios from 'axios'
import store from '@/store'

export default () => {

  let baseURL;

  if(process.env.VUE_APP_ENVIRONMENT === 'dev'){
    baseURL = process.env.VUE_APP_DEV_API_URL;
  } else if (process.env.VUE_APP_ENVIRONMENT === 'staging'){
    baseURL = process.env.VUE_APP_STAGING_API_URL;
  } else if (process.env.VUE_APP_ENVIRONMENT === 'prod'){
    baseURL = process.env.VUE_APP_API_URL;
  } 

  console.log('the base URL is ', baseURL)

  return axios.create({
      baseURL,
      headers: {
        'Authorization': `Bearer ${store.getters["Auth/getToken"]}`
      }
  })

}


