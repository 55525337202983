import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  { path: '/dashboard/lender/add', name: 'lenderAdd', meta: { adminOnly: true }, component: () => import('@/views/lender/Add.vue') },
  { path: '/dashboard/lender/edit/:lenderId', name: 'lenderEdit', props: true, meta: { adminOnly: true }, component: () => import('@/views/lender/Add.vue') },
  { path: '/dashboard/lender/read/:lenderId', props: true, meta: { adminOnly: true }, component: () => import('@/views/lender/Read.vue') },

  { path: '/dashboard/lenderContacts/add', name: 'lenderContactAdd', meta: { adminOnly: true }, component: () => import('@/views/lenderContacts/Add.vue') },
  { path: '/dashboard/lenderContacts/edit/:lenderContactId', name: 'lenderContactEdit', props: true, meta: { adminOnly: true }, component: () => import('@/views/lenderContacts/Add.vue') },
  { path: '/dashboard/lenderContacts/read/:lenderContactId', props: true, meta: { adminOnly: true }, component: () => import('@/views/lenderContacts/Read.vue') },

  { path: '/dashboard/lenderAccount/add', name: 'lenderAccountAdd', meta: { adminOnly: true }, component: () => import('@/views/lenderAccount/Add.vue') },
  { path: '/dashboard/lenderAccount/edit/:lenderAccountId', name: 'lenderAccountEdit', props: true, meta: { adminOnly: true }, component: () => import('@/views/lenderAccount/Add.vue') },
  { path: '/dashboard/lenderAccount/read/:lenderAccountId', props: true, meta: { adminOnly: true }, component: () => import('@/views/lenderAccount/Read.vue') },

  { path: '/dashboard/lenderAddress/add', name: 'lenderAddressAdd', props: true, meta: { adminOnly: true }, component: () => import('@/views/lenderAddress/Add.vue') },
  { path: '/dashboard/lenderAddress/edit/:lenderAddressId', name: 'lenderAddressEdit', props: true, meta: { adminOnly: true }, component: () => import('@/views/lenderAddress/Add.vue') },
  // { path: '/dashboard/lenderAddress/read/:lenderAddressId', props: true, meta: { adminOnly: true }, component: () => import('@/views/lenderAddress/Read.vue') },

  { path: '/dashboard/lender/list', meta: { adminOnly: true }, component: () => import('@/views/lender/List.vue') },
  { path: '/dashboard/lender/search', meta: { adminOnly: true }, component: () => import('@/views/lender/Search.vue') },

  { path: '/dashboard/deal/list', meta: { adminOnly: true }, component: () => import('@/views/deal/List.vue') },
  { path: '/dashboard/deal/add', name: 'dealAdd', meta: { adminOnly: true }, component: () => import('@/views/deal/Add.vue') },
  { path: '/dashboard/deal/edit/:dealId', name: 'dealEdit', props: true, meta: { adminOnly: true }, component: () => import('@/views/deal/Add.vue') },
  { path: '/dashboard/deal/read/:dealId', props: true, meta: { adminOnly: true }, component: () => import('@/views/deal/Read.vue') },
  { path: '/dashboard/deal/assignLenders/:dealId', props: true, meta: { adminOnly: true }, component: () => import('@/views/deal/AssignLenders.vue') },
  { path: '/dashboard/deal/resendDealToLenders/:dealId', props: true, meta: { adminOnly: true }, component: () => import('@/views/deal/ResendDealToLenders.vue') },

  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: { adminOnly: true },
    component: () => import('@/views/Dashboard.vue'),

  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.adminOnly)) {
    if (localStorage.getItem('token')) {
      next();
    } else {
      next('/')
    }
  } else {
    next();
  }

});

export default router
