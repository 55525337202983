<template>
  <div>
    <v-btn @click="login" v-if="!authStatus">Login</v-btn>
    <!-- <v-btn @click="login" v-if="!authStatus"
      >Login <v-icon color="red" class="ml-3">mdi-pill</v-icon></v-btn
    > -->
    <v-btn v-else @click="logout">Logout</v-btn>

    <v-dialog v-model="inactivityDialog" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 red lighten-2">
          Hey Captain! Wakey Wakey!!
        </v-card-title>

        <v-card-text>
          <br />
          You must have fallen asleep at the desk. There has been no activity
          for so long the login token is going to expire or has already. Let's
          renew it before doing any further actions or log out now!
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="renewLogin"> Renew Login </v-btn>
          <v-btn color="primary" text @click="logout"> Logout </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <!-- <v-btn v-else @click="logout"
      >Logout <v-icon color="blue" class="ml-3">mdi-pill</v-icon></v-btn
    > -->
</template>

<script>
import * as Msal from "msal";
import { mapActions } from "vuex";

export default {
  name: "Authentication",
  data() {
    return {
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      warningTimer: null,
      logoutTimer: null,
      warningZone: false,
      inactivityDialog: false,
    };
  },
  computed: {
    authStatus() {
      return this.$store.getters["Auth/getLoggedIn"];
    },
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch("Auth/login");
      } catch (error) {
        // console.log(error)
      }

      // this.loggedIn = await this.$store.getters["Auth/getLoggedIn"];
    },

    async logout() {
      await this.$store.dispatch("Auth/logout");
      this.inactivityDialog = false;
    },
    resetTimer() {
      clearTimeout(this.warningTimer);
      this.setTimers();
    },
    setTimers() {
      this.warningTimer = setTimeout(this.warningMessage, 10 * 60 * 1000);
      // this.logoutTimer = setTimeout(this.warningMessage, 8 * 1000);

      // this.warningZone = false;
    },

    async renewLogin() {

      try {
        await this.$store.dispatch("Auth/login");
      } catch (error) {
        // console.log(error)
      }

      let tokenExpiry = this.$store.getters["Auth/getTokenExpiry"];

      let tokenMinus5Min = new Date(tokenExpiry - 5 * 60 * 1000);

      let now = new Date();

      if (now >= tokenMinus5Min) {
        this.inactivityDialog = true;
      } else {
        this.resetTimer();
        this.inactivityDialog = false;
      }
    },

    async warningMessage() {
      if (this.$store.getters["Auth/getLoggedIn"] == true) {
        await this.renewLogin();

        let tokenExpiry = this.$store.getters["Auth/getTokenExpiry"];

        let tokenMinus7Min = new Date(tokenExpiry - 7 * 60 * 1000);

        let now = new Date();

        if (now >= tokenMinus7Min) {
          this.inactivityDialog = true;
        } else {
          this.resetTimer();
        }
      }
    },

    /*

instead of a warning timer, you would run a renew login command? 
If user logged in = true then check the token expiery if u are within 30 minutes
if you are within 30 minutes, then do the auth renewal and reset the time
if youare not within 30 minutes, just reset the timer


*/
  },

  async mounted() {
    // this.loggedIn = await this.$store.getters["Auth/getLoggedIn"];

    // added this to end of the foreach so the function gets access to this....
    this.events.forEach(function (event) {
      window.addEventListener(event, this.resetTimer);
    }, this);
  },
};
</script>

<style>
.loginLink {
  cursor: pointer;
  background-color: white !important;
  padding: 7px 15px;
  border-radius: 15px;
  font-weight: bold;
}
</style>
