<template>
  <nav>
    <v-app-bar flat app class="grey darken-4">
      <v-app-bar-nav-icon
        v-if="loggedIn"
        color="white"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-spacer />
      <v-img
        class="mx-5"
        style="margin-top: -10px; transform: scaleX(-1)"
        src="@/assets/cannon_icon.png"
        max-height="40"
        max-width="40"
        contain
        d-inline-flex
      ></v-img>

      <v-app-bar-title shrink-on-scroll class="white--text" style="text-no-wrap"
        >Deal Cannon
        <!-- <img class="cannonIcon" src="@/assets/cannon_icon.png" alt=""> -->
      </v-app-bar-title>

      <v-img
        class="mx-5"
        style="margin-top: -10px"
        src="@/assets/cannon_icon.png"
        max-height="40"
        max-width="40"
        contain
        d-inline-flex
      ></v-img>
      <v-spacer />

      <authentication></authentication>

      <!-- <v-btn color="grey darken-2" class="white--text">
        <span>Sign In</span>
        <v-icon color="white" right>mdi-account-circle</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-navigation-drawer
      v-if="loggedIn"
      app
      class="grey darken-4"
      v-model="drawer"
    >
      <v-list>
        <v-list-item @click="$router.push({ path: '/' })">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>

        <v-list-group
          :value="false"
          prepend-icon="mdi-account-circle"
          color="white"
        >
          <template v-slot:activator>
            <v-list-item-title class="white--text">Lenders</v-list-item-title>
          </template>

          <v-list-item link to="/dashboard/lender/list" class="ml-3">
            <v-list-item-title class="sub_item_title" right>List Lenders</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-format-list-bulleted</v-icon></v-list-item-icon
            >
          </v-list-item>

          <v-list-item link to="/dashboard/lender/add" class="ml-3">
            <v-list-item-title class="sub_item_title" right>Add Lender</v-list-item-title>

            <v-list-item-icon> <v-icon>mdi-plus</v-icon></v-list-item-icon>
          </v-list-item>
        </v-list-group>

        <v-list-group
          :value="false"
          prepend-icon="mdi-currency-usd"
          color="white"
        >
          <template v-slot:activator>
            <v-list-item-title class="white--text">Deals</v-list-item-title>
          </template>



                    <v-list-item link exact :to="{ path: '/dashboard/deal/list', query: {status: 'all'} }" class="ml-3">
    
              <v-list-item-title class="sub_item_title" right>List All Deals</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-format-list-checks</v-icon></v-list-item-icon
              >
          </v-list-item>


          <v-list-item link exact :to="{ path: '/dashboard/deal/list', query: {status: 'active'} }" class="ml-3">
    
              <v-list-item-title class="sub_item_title" right>Active Deals</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-account-alert</v-icon></v-list-item-icon
              >
          </v-list-item>

                              <v-list-item link exact :to="{ path: '/dashboard/deal/list', query: {status: 'awarded'} }" class="ml-3">
    
              <v-list-item-title class="sub_item_title" right>Awarded Deals</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-account-star</v-icon></v-list-item-icon
              >
          </v-list-item>

                    <v-list-item link exact :to="{ path: '/dashboard/deal/list', query: {status: 'completed'} }" class="ml-3">
    
              <v-list-item-title class="sub_item_title" right>Completed Deals</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-piggy-bank</v-icon></v-list-item-icon
              >
          </v-list-item>
 
          <v-list-item link exact :to="{ path: '/dashboard/deal/list', query: {status: 'inactive'} }" class="ml-3">
    
              <v-list-item-title class="sub_item_title" right>Inactive Deals</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-coffin</v-icon></v-list-item-icon
              >
          </v-list-item>


          <v-list-item link to="/dashboard/deal/add" class="ml-3">
            <v-list-item-title class="sub_item_title" right>Add New Deal</v-list-item-title>

            <v-list-item-icon> <v-icon>mdi-plus</v-icon></v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import Authentication from "@/components/Authentication";

export default {
  components: { Authentication },
  data() {
    return {
      name: "navbar",
      components: {
        Authentication,
      },
      drawer: null,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters["Auth/getLoggedIn"];
    },
  },
};
</script>

<style scoped>
.cannonIcon {
  height: 30px;
}
.v-list-item__title.sub_item_title{
  font-size: .75rem;
}
</style>