export function getToken ( state ) {

    return state.token;

}

export function getTokenExpiry ( state ) {
    return state.expiresOn;
}

export function getLoggedIn ( state ) {

    return state.loggedIn;

}


export function getAuthUser ( state ) {

    return state.user;

}

