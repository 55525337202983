<template>
  <v-app class="black">
    <Navbar />
    <v-main class="ma-4">
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  name: "App",

  components: {
    Navbar,
  },

  data: () => ({
    //
  }),
  async created() {
   
    if (localStorage.getItem("token")) {
      await this.$store.dispatch("Auth/silentLogin");
      await this.$store.dispatch("Auth/getAuthUserInfo");
    }
  },
};
</script>

<style >
/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

</style>