import * as Msal from "msal";

import API from "@/plugins/API";

let redirectUri;

if(process.env.VUE_APP_ENVIRONMENT === 'dev'){
  redirectUri = process.env.VUE_APP_DEV_SPA_URL;
} else if (process.env.VUE_APP_ENVIRONMENT === 'staging'){
  redirectUri = process.env.VUE_APP_STAGING_SPA_URL;
} else if (process.env.VUE_APP_ENVIRONMENT === 'prod'){
  redirectUri = process.env.VUE_APP_SPA_URL;
} 


const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
    authority: process.env.VUE_APP_MSAL_AUTHORITY,
    redirectUri
  },
  cache: {
    cacheLocation: "localStorage"
  }
};

let client = new Msal.UserAgentApplication(msalConfig);

const request = {
  scopes: ["user.read"],
  forceRefresh: false

};

export async function login({ getters, dispatch }) {

  if (getters.getToken) {

    dispatch('silentLogin');

  } else {

  await client.loginPopup(request);
    dispatch('silentLogin');

  }

}

export async function silentLogin({ commit, dispatch }) {

  let tokenResponse = await client.acquireTokenSilent(request);

  await client.acquireTokenPopup

  if (!tokenResponse.error && tokenResponse.accessToken) {

    commit('setToken', {token: tokenResponse.accessToken, expiresOn: tokenResponse.expiresOn })
  } else {
    localStorage.clear();
    commit('logout');
    dispatch('login')
  }

}

export async function getAuthUserInfo({getters, commit}){

  let user = await API().get('/user/me')

  if(user.status == 200){
    commit('setAuthUserInfo', user.data)
  }

}

export async function logout({ commit }) {
  localStorage.clear();

  commit('logout');
  this.$router.push('/')
}
