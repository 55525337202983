export function setToken(state, value) {

    state.token = value.token;
    state.loggedIn = true;
    state.expiresOn = value.expiresOn;
    window.localStorage.setItem('token', value.accessToken)
}

export function logout(state) {
    state.token = null;
    state.loggedIn = false;
    state.expiresOn = null;
    window.localStorage.removeItem('token')

}

export function setAuthUserInfo(state, value) {
    state.user = value;
  

}