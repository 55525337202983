<template>
  <div class="home ma-n4">
    <div class="gifWrapper">
      <div v-if="homeImg">
        <img class="cannonGif" src="@/assets/cannon_loop_once.gif" alt="" />
               <blockquote class="my-5" style="margin-left:30px;">
          &ldquo;Embrace the Deal Cannon... It makes all your wildest deals come true, one blast at a
          time.&rdquo; <br />&mdash; Captain Morgan
        </blockquote>
      </div>
      <div v-else>
        <img class="cannonGif" src="@/assets/cannon.jpg" alt="" />

 
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Home",
  data() {
    return {
      quote: null,
    };
  },
  components: {},
  computed: {
    homeImg() {
      return this.$store.getters["Auth/getLoggedIn"];
    },
  },
  mounted() {},
};
</script>

<style scoped>
.gifWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cannonGif {
  min-width: 700px;
  max-width: 750px;
  margin-top: 5%;
  border: 10px solid rgb(39, 39, 39);
}
</style>
